.debrief-message {
  padding: 9.6rem 4.8rem;
}

.debrief-body-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 4.8rem;
  border-bottom: 2px solid #cdbda7;
  margin-top: 3.4rem;
}

.debrief-nav-item {
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.debrief-nav-item--selected {
  font-weight: 700;
  cursor: auto;
  border-bottom: 2px solid #010101;
}

.selected-dark {
  border-bottom: 2px solid #010101;
}

.selected-light {
  border-bottom: 2px solid #f3f5f2;
}

.debrief-nav-item:hover:not(.debrief-nav-item--selected) {
  border-bottom: 2px solid #cacaca;
}

/* HOUSES */

.house-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.debrief-genre-title--container {
  margin: 2.4rem 0;
  display: flex;
  align-items: center;
  gap: 4.8rem;
}

.debrief-score-button {
  position: relative;
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.debrief-score-button--selected,
.debrief-score-button:hover {
  filter: brightness(85%);
}

.debrief-genre-title-card {
  width: 5rem;
}

.debrief-house-grid {
  display: grid;
  gap: 1.7rem;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 4.8rem;
  border-bottom: 2px solid #010101;
}

.debrief-flag {
  width: 12rem;
}

.debrief-flag--clickable {
  width: 12rem;
  border: 2px solid transparent;
}

.debrief-flag--clickable:hover {
  border: 2px solid #010101;
}

.debrief-flag--small {
  width: 6rem;
  border: 2px solid transparent;
}

.debrief-flag--small:hover {
  border: 2px solid #010101;
}

.house-go-back {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  max-width: 25rem;
  margin: 2.4rem 0;
}

.debrief-house-masterlist--stories {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 4.8rem;
  gap: 1.2rem;
}

.house-master-list--story {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.house-selector-icon {
  position: absolute;
  right: 3%;
  bottom: 3%;
}

.my-debrief-story {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
}

.my-debrief-story--content {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  column-gap: 1rem;
}

.my-debrief--title-wrapper {
  color: inherit;
  text-decoration: none;
}

.published-title {
  pointer-events: all;
  cursor: pointer;
  text-decoration: underline;
}

.text-area--resize-none {
  resize: none;
}

.text-area--resize-vertical {
  resize: vertical;
}

.my-debrief--controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comment-container {
  border: #010101 2px solid;
  border-radius: 11px;
}

.leave-a-comment-section {
  display: grid;
  padding: 2.4rem;
  grid-template-columns: 1fr 4fr;
  /* box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15); */
  column-gap: 2.4rem;
}

.leave-a-comment-section--large {
  grid-template-columns: 1fr 6fr;
}

.message-expand {
  grid-column: 2 / -1;
}

.leave-a-comment-selection--shadow {
  border: none;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
}

.debrief-stories {
  display: flex;
  flex-direction: column;
  margin: 3.6rem 0;
  gap: 2.4rem;
}

.debrief-story-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: space-between;
  padding: 2.4rem;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
  column-gap: 2.4rem;
  transition: all 0.2s;
  text-decoration: none;
  color: inherit;
}

.debrief-story-container--nightMode {
  color: #c2c4c2;
  background-color: #08161c;
}

.debrief-clickable-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
}

.debrief-clickable-container:hover {
  text-decoration: underline;
  cursor: pointer;
}

.debrief-story-container--profile {
  grid-template-columns: 2fr 3fr;
  width: 100% !important;
}

.debrief-story-container--tavern {
  grid-template-columns: 3fr 5fr 2fr;
  width: 100% !important;
}

.debrief-story-container:hover {
  background-color: #f3efe9;
  transform: scale(1.02);
}

.debrief-story-container--nightMode:hover {
  background-color: #112c38;
}

.debrief-house-duel {
  display: grid;
  grid-template-columns: 10fr 1fr;
  background-color: #eaf1f4;
  padding: 1.2rem;
  border: #040b0e 2px solid;
  border-radius: 11px;
  justify-content: center;
  align-items: center;
}

.debrief-house-duel--pending {
  grid-template-columns: 1fr;
}

.debrief-final-showdown-list {
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
}

.debrief-final-showdown-matches {
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  padding: 2.4rem;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
}

.debrief-final-showdown-match {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 1fr 5fr 1fr;
  background-color: #eaf1f4;
  padding: 1.2rem;
  border: #040b0e 2px solid;
  border-radius: 11px;
  justify-content: center;
  align-items: center;
  column-gap: 1.2rem;
}

.debrief-final-showdown-genres {
  margin: 3.6rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
}

.debrief-battle-card:nth-child(1) {
  display: flex;
  justify-content: end;
}

.debrief-battle-card:nth-child(3) {
  display: flex;
  justify-content: end;
}

.list-of-fs-rounds {
  display: flex;
  flex-direction: column;
  gap: 5.4rem;
}

.debrief-feedback-list {
  display: flex;
  flex-direction: column;
  margin: 4.8rem 0;
  gap: 4.8rem;
}

.trophy-debrief {
  height: 5rem;
  width: 5rem;
}

.sub-card-selector {
  margin: 0 1rem;
  max-width: 80rem;
}

.sub-card-selector-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;
}

.sub-card-selector-grid--1col {
  grid-template-columns: 1fr;
}

.card-collection-array {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.card-collapse-button-wrapper {
  position: relative;
  grid-column: span 5;
  cursor: pointer;
}

.card-collapse-button-wrapper:hover {
  background: rgba(255, 255, 255, 0.8);
}

.card-collapse-button {
  position: absolute;
  margin-top: 5rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.collection-card--mini {
  width: 8rem;
  height: auto;
  border-radius: 5px;
  margin: 0 auto;
  border: solid 3px rgba(1, 1, 1, 0);
  cursor: pointer;
}

.collection-card--mini-owned {
  border: solid 3px rgb(114, 175, 2);
}

.card-select:hover {
  border: solid 3px #010101;
}

.card-select--collapse-button {
  -webkit-mask-image: -webkit-gradient(
    linear,
    top,
    bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.prompt-selector {
  padding: 1rem;
}

.prompt-selector--highlighted {
  border: solid 3px #010101;
}

.prompt-selector--error {
  border: solid 3px #782727;
}

.card-selector-wrapper {
  padding: 2.8rem;
  border-left: solid 2px #010101;
  border-right: solid 2px #010101;
  border-bottom: solid 2px #010101;
}

.raid-list {
  display: flex;
  flex-direction: column;
}

.raid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2.4rem;
  padding: 3.6rem;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
}

.raid-container--avail {
  box-shadow: none;
  border: solid 2px #010101;
}

.raid-container--done {
  box-shadow: none;
  border: solid 2px gray;
}

.raid-container--white {
  box-shadow: none;
  border: solid 2px #c2c4c2;
}

.my-debrief--message-wrap {
  justify-content: start !important;
}

.reply-to-comment-container {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.progress-bar-wrapper {
  overflow: hidden;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-left: #010101 2px solid;
  border-right: #010101 2px solid;
  border-top: #010101 2px solid;
}

.feedback-thumb {
  height: 2.4rem;
  width: 2.4rem;
}

.feedback-thumb--down {
  color: #ae7d7d;
}

.feedback-thumb--up {
  color: #7fa8ba;
}

.feedback-thumb--up--pressed,
.feedback-thumb--up:hover {
  color: #2a6e8c;
}

.feedback-thumb--down--pressed,
.feedback-thumb--down:hover {
  color: #782727;
}

.collapsed-my-debrief-story {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  padding: 2.4rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
}

.story-cover-upload-image {
  object-fit: cover;
  height: 24rem;
  width: 15rem;
}

.story-cover {
  object-fit: cover;
  height: 48rem;
  width: 30rem;
  margin-top: 2.4rem;
}
