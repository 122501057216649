/*******************************************/
/* BELOW 992px (Tabelts) */
/*******************************************/

@media (max-width: 62em) {
}

/*******************************************/
/* BELOW 720px (Smaller Tabelts) */
/*******************************************/

@media (max-width: 45em) {
  .collapsed-my-debrief-story {
    grid-template-columns: 1fr 3fr;
    padding: 1.4rem;
  }

  .my-debrief-story--content {
    grid-template-columns: 1fr;
  }

  .house-master-list--story {
    gap: 1.2rem;
  }

  .debrief-house-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.4rem;
  }
}

/*******************************************/
/* BELOW 544px (Phones) */
/*******************************************/

@media (max-width: 34em) {
  .debrief-story-container {
    padding: 1rem;
    gap: 0.5rem;
  }

  .debrief-house-grid {
    gap: 1.4rem;
  }

  .house-selector-icon {
    top: 3%;
    background-color: rgba(243, 245, 242, 0.26);
  }

  .debrief-house-masterlist--stories {
    grid-template-columns: 1fr;
  }

  .debrief-genre-title--container {
    gap: 2.4rem;
  }

  .comment-container {
    display: inline-block;
    overflow-wrap: break-word;
  }

  .debrief-final-showdown-matches {
    padding: 1.2rem;
  }

  .debrief-final-showdown-match {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  .debrief-body-nav {
    gap: 2rem;
    margin-bottom: 1rem;
  }

  .debrief-nav-item {
    font-size: 1.8rem;
  }

  .debrief-message {
    padding: 4.8rem 2.2rem;
  }

  .sub-card-selector-grid {
    grid-template-columns: 1fr;
  }

  .card-selector-wrapper {
    padding: 0;
    border: none;
  }

  .prompt-selector-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
    padding: 0;
    margin: 0;
  }

  .prompt-selector {
    padding: 1rem 0rem;
  }

  .prompt-selector-container {
    justify-self: center;
  }

  .prompt-selector-container:first-child {
    grid-row: 1;
    grid-column: span 2;
  }

  .prompt-selector-container:nth-child(2) {
    justify-self: right;
  }

  .prompt-selector-container:nth-child(3) {
    justify-self: left;
  }
}

/*******************************************/
/* BELOW 352px (THIN PHONES) */
/*******************************************/

@media (max-width: 22em) {
  .my-debrief-story {
    padding: 0.5rem;
  }

  .debrief-genre-title-card {
    width: 3rem;
  }

  .debrief-genre-title--container {
    gap: 1.2rem;
  }

  .debrief-flag {
    width: 9rem;
  }

  .debrief-flag--small {
    width: 4rem;
  }

  .leave-a-comment-section {
    grid-template-columns: 1fr 6fr;
    padding: 2.4rem 0.5rem;
    column-gap: 0.5rem;
  }

  .debrief-final-showdown-match {
    padding: 0.5rem;
    column-gap: 0.5rem;
  }

  .debrief-body-nav {
    gap: 1.2rem;
  }

  .debrief-nav-item {
    font-size: 1.5rem;
  }

  .card-collection-array {
    gap: 0.5rem;
  }

  .collection-card--mini {
    width: 5rem;
    height: auto;
  }

  .raid-container {
    padding: 1rem;
    margin: 1rem;
  }
}
