/* HERO SECTION */

.section-hero {
  background-image: linear-gradient(#e6ded3, #f3f5f2);
}

.section-hero--black {
  background-image: linear-gradient(#1a1a1a, #08161c);
}

.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  max-width: 130rem;
  margin: 0 auto;
  row-gap: 3.2rem;
  padding: 2.4rem 3.2rem 4.8rem 3.2rem;
}

.heading-left-side {
  justify-self: end;
  padding-right: 1.1rem;
}

.heading-right-side {
  padding-left: 1.1rem;
}

.landing-logo-container {
  align-self: start;
  height: 37rem;
  margin: 0 auto;
}

.landing-logo {
  transform: perspective(1400px) rotateY(-10deg);
  transition: transform 0.3s ease 0s;
  height: 100%;
  border-radius: 9px;

  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.landing-logo:hover {
  transform: perspective(1400px) rotateY(-5deg);
}

.first-cta-buttons {
  margin-left: 8rem;
}

.first-cta-container {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.hero-description {
  font-size: 3.6rem;
  line-height: 1.23;
  font-weight: 700;
  margin-bottom: 4.8rem;
}

.current-participants {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-top: 4rem;
  margin-left: 8rem;
}

.participant-imgs {
  display: flex;
}

.participant-img {
  height: 4.8rem;
  width: 4.8rem;
  border-radius: 50%;
  margin-right: -1.6rem;
  border: 3px solid #e6ded3;
}

.participant-img--black-border {
  border: 3px solid #010101;
}

.participant-img:last-child {
  margin-right: 0;
}

.participants-text {
  font-size: 1.8rem;
  font-weight: 600;
}
.participants-text span {
  color: #4e3615;
  font-weight: 600;
}

/*******************************************/
/* HOW IT WORKS SECTION */
/*******************************************/

.section-how {
  padding: 9.6rem 0;
}

.step-number {
  font-size: 8.6rem;
  font-weight: 600;
  color: #7fa8ba;
  margin-bottom: 1.2rem;
}

.step-img-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-img {
  transform: perspective(550px) rotateY(-10deg);
  transition: transform 0.3s ease 0s;
  border-radius: 9px;
  width: 60%;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.step-img:hover {
  transform: perspective(1400px) rotateY(-5deg);
}

.step-img--left {
  transform: perspective(550px) rotateY(10deg);
  transition: transform 0.3s ease 0s;
  border-radius: 9px;
  width: 60%;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.step-img--left:hover {
  transform: perspective(1400px) rotateY(5deg);
}

.preview-img--left {
  transform: perspective(300px) rotateY(10deg);
  transition: transform 0.3s ease 0s;
  border-radius: 9px;
  width: 25%;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.preview-img--left:hover {
  transform: perspective(764px) rotateY(5deg);
}

.preview-img {
  transform: perspective(300px) rotateY(-10deg);
  transition: transform 0.3s ease 0s;
  border-radius: 9px;
  width: 25%;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
    rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
}

.preview-img:hover {
  transform: perspective(764px) rotateY(-5deg);
}

.preview-flex {
  display: flex;
  margin-bottom: 3.6rem;
}

.preview-flex--no-margin {
  margin: 0 !important;
}

.img-smaller {
  height: 40rem;
  width: 40rem;
}

.padding-left--home {
  padding-left: 4.8rem;
}

.final-testimonial-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  column-gap: 3.6rem;
  row-gap: 3.6rem;
}

/*******************************************/
/* INDUSTRY JUDGES */
/*******************************************/

.center-text .heading-secondary {
  margin-bottom: 3.6rem;
}

.judgeImgs-and-description {
  column-gap: 4.8rem;
  max-width: 160rem;
  padding: 2.4rem 3.2rem 4.8rem 3.2rem;
  grid-template-columns: 1fr;

  margin: 0 auto;
  margin-bottom: 4.8rem;
  row-gap: 2.4rem !important;
}

.grid .judge-cards {
  gap: 2.4rem;
  margin-bottom: 0 !important;
}

.judge-description {
  align-self: center;
  text-align: center;
}

.judge-card {
  transition: all 0.2s;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  border-radius: 11px;
  background-size: contain;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  /* width is 400 and height is 640*/
  width: 24rem;
  height: 38.4rem;
  justify-self: center;

  display: flex;
  flex-direction: column;
}

.judge-card:hover {
  box-shadow: 0 0 0 2px #1a1a1a;
  transform: scale(0.98);
}

.card-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 20.6rem;
}

.clickable-accolade {
  display: block;
  padding: 0;
  margin-left: 2.3rem;
  margin-bottom: 0.5rem;
}

.accolade {
  display: block;
  width: 8rem;
}

.accolade--mvw {
  display: block;
  width: 5rem;
  margin-top: 5.67rem;
}

.clickable-accolade:not(:focus) .accolade {
  pointer-events: none;
}

.clickable-accolade:not(:focus) .accolade--mvw {
  pointer-events: none;
}

.judge-card .list {
  margin-left: 0.8rem;
  gap: 0.5rem;
}

.judge-card .list .list-item {
  font-size: 1.4rem;
  text-align: left;
  gap: 0.5rem;
}

.more-info {
  padding-left: 21rem;
  color: #010101;
  margin-top: -8%;
}

.more-info:hover {
  color: #782727;
}

/*******************************************/
/* TESTIMONIALS SECTION */
/*******************************************/

.section-testimonials {
  display: grid;
  /* Just remember that these do not have to be 1
  you can treat these as percentages and it
  may be easier to visualize*/
  grid-template-columns: 55fr 45fr;
  align-items: center;
  background-color: #e6ded3;
  margin-bottom: 6.4rem;
}

.testimonials-container {
  padding: 9.3rem;
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4.8rem;
  column-gap: 8rem;
}

.testimonial-img {
  width: 9.6rem;
  border-radius: 50%;
  margin-bottom: 1.2rem;
}

.testimonial-text {
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 1.6rem;
}

.testimonial-name {
  font-size: 1.6rem;
  color: #6f6f6f;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  padding: 1.6rem;
}

.gallery-item {
  overflow: hidden;
}

.gallery-item img {
  display: block; /* This removed annoying auto spacing on images*/
  width: 100%;
  transition: all 0.4s;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

.feature-icon {
  color: #2a6e8c;
  height: 3.2rem;
  width: 3.2rem;
  background-color: #f3efe9;
  margin-bottom: 3.2rem;
  padding: 1.6rem;
  border-radius: 50%;
}

.feature-title {
  font-size: 2.4rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 1.6rem;
}

.feature-text {
  font-size: 1.8rem;
  line-height: 1.8;
}

.home-page-countdown {
  background-color: #e6ded3;
}

.home-page-countdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 9.6rem;
  padding-bottom: 4.8rem;
}

.hp-countdown-wrapper {
  display: flex;
  align-items: center;
}

.hp-title {
  margin: 0;
  text-align: center;
}

.hp-buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
}

.queen-landscape-background {
  width: 100%;
}

.queen-section {
  position: relative;
}

.queen-section-prize-text {
  position: absolute;
  font-size: 12rem;
  text-shadow: -2px -2px 0 #010101, 2px -2px 0 #010101, -2px 2px 0 #010101,
    2px 2px 0 #010101;
  right: 9%;
  bottom: 1%;
}

.hero-cta-section {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  top: 3%;
  right: 1%;
}

.hero-description--landscape {
  font-weight: 700;
  margin-bottom: 2.8rem;
  margin-left: 8rem;
  padding-right: 3rem;
  font-size: 2.6rem;
  line-height: 1.23;
}

.hero-title-container {
  display: flex;
  margin-bottom: 3.6rem;
  margin-top: 1.2rem;
  margin-right: 3.6rem;
  margin-left: 8rem;
}

.hero-cta-container {
  padding: 4.8rem 3.6rem 0rem 0rem;
}

.cta-on-hero {
  display: block;
}

.cta-under-hero {
  display: none;
}

.mvw-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 8rem;
}

.still-not-joined-bground {
  background-image: linear-gradient(#f3f5f2, #b5ca8d);
}

.still-not-joined-bground--dark {
  background-image: linear-gradient(transparent, #24281c);
}

.triangle-element {
  height: 10rem;
  background-color: #b5ca8d;
  background: linear-gradient(
    to bottom left,
    #b5ca8d 0%,
    #b5ca8d 50%,
    #f8dc55 50%,
    #f8dc55 100%
  );
}

.triangle-element--dark {
  height: 10rem;
  background-color: #24281c;
  background: linear-gradient(
    to bottom left,
    #24281c 0%,
    #24281c 50%,
    #635822 50%,
    #635822 100%
  );
}

.peer-vs-pro {
  display: flex;
  justify-content: center;
  align-items: start;
}

.battle-type-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.battle-type-list {
  margin-left: 2rem;
}

.battle-type-img {
  width: 100%;
  height: auto;
}

.battle-face-description-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
}

.battle-face-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.battle-face {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.battles-dates-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 3.6rem;
}

.battle-date {
  min-height: 12rem;
  min-width: 40rem;
  display: flex;
  justify-content: end;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}

.battle-date:hover {
  background-color: rgba(42, 111, 140, 0.61);
  color: #1a1a1a;
  min-height: 14rem;
}
